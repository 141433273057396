import React from "react";
import { Helmet } from "react-helmet";

import Btn from "../components/Btn";
import Header from "../components/Header";
import Layout from "../components/layout";
import badgeImg from "../images/ted_masterclass_completion_badge.jpg";
import badgeSvg from "../images/ted_masterclass_completion_badge.svg";
import tw from "tailwind.macro";

import { css } from "@emotion/core";
import { rem } from "../styles/utilities.styles";
import { lg } from "../styles/breakpoints";

const styles = {
  wrap: css`
    ${tw`text-center max-w-lg mx-auto px-10`}
    margin-top: ${rem(56)};

    @media (${lg}) {
      margin-top: ${rem(96)};
    }
  `,
  title: tw`text-4xl lg:text-5xl font-bold mb-4 leading-tight tracking-tight`,
  tagline: tw`text-lg lg:text-xl font-light leading-normal mb-0 tracking-tight`,
  badge: css`
    max-width: ${rem(200)};
    margin: 0 auto;
    padding: ${rem(48)} 0;

    @media (${lg}) {
      max-width: ${rem(240)};
    }
  `,
  text: tw`text-lg leading-normal font-light mb-4`,
  btn: css`
    padding: ${rem(16)} ${rem(32)} ${rem(20)};
  `,
};

const BadgePage = ({ cta, learnMore, tagline, title }) => (
  <Layout includeMetaTags={false}>
    <Helmet
      title={
        "I completed TED Masterclass, TED's official public speaking course"
      }
      meta={[
        {
          name: "description",
          content:
            "TED Masterclass -- TED’s official public speaking course -- will teach you how to identify, develop and share your best ideas with the world.",
        },
        {
          property: "fb:app_id",
          content: "201021956610141",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "I completed TED Masterclass, TED's official public speaking course",
        },
        {
          property: "og:description",
          content:
            "TED Masterclass -- TED’s official public speaking course -- will teach you how to identify, develop and share your best ideas with the world.",
        },
        {
          property: "og:url",
          content: "https://masterclass.ted.com/congratulations",
        },
        {
          property: "og:image",
          content: `https://masterclass.ted.com${badgeImg}`,
        },
        {
          property: "og:image:width",
          content: 1200,
        },
        {
          property: "og:image:height",
          content: 630,
        },
      ]}
    />
    <Helmet>
      <link
        rel="canonical"
        href="https://masterclass.ted.com/congratulations"
      />
    </Helmet>
    <Header />
    <div css={styles.wrap}>
      <h1 css={styles.title}>{title}</h1>
      <p css={styles.tagline}>{tagline}</p>

      <img
        css={styles.badge}
        src={badgeSvg}
        alt="I completed TED Masterclass"
      />

      <p css={styles.text}>{learnMore}</p>
      <Btn {...cta} css={styles.btn} />
    </div>
  </Layout>
);

BadgePage.defaultProps = {
  title: "I completed TED Masterclass",
  tagline: "TED's official public speaking course",
  learnMore: "Learn more about TED Masterclass",
  cta: {
    to: "/",
  },
};

export default BadgePage;
